@font-face {
  font-family: monumentExtended;
  src: url(./fonts/MonumentExtended-Regular.otf);
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'monumentExtended', sans-serif !important;
  font-weight: 400;
  background-image: url('../public/nft_background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  color: #FFFFFF !important;
}

.title {
  font-family:  'monumentExtended', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  color: #FFFFFF;
  text-shadow: 0px 0px 30px #4377FE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fontSmall {
  font-size: 14px !important;
}

.navbar {
  margin: 0;
  padding: 0 !important;
  backdrop-filter: blur(24px) !important;
  background-color: rgba(31,31,31,.35) !important;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(3, 3, 17, .45);
  transition: background-color 0.5s ease;
  border-bottom: 1px solid rgba(255,255,255, .18);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}

.header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 80px;
  width: 100%;
}

.header__logo {
  z-index: 1;
  width: auto;
  height: 80px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.header__logo img {
  height: 80px;
  width: auto;
  position: relative;
  top: 0;
}

.header__btn {
  position: relative;
  width: 20px;
  height: 22px;
  display: block;
  left: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
  cursor: pointer;
}

.header__btn span:first-child {
  top: 0;
}

.header__btn--active span:first-child {
  transform: rotate(45deg);
  top: 10px;
}

.header__btn span:nth-child(2) {
  top: 10px;
  width: 16px;
}

.header__btn--active span:nth-child(2) {
  opacity: 0;
}

.header__btn span:last-child {
  top: 20px;
  width: 8px;
}

.header__btn--active span:last-child {
  width: 24px;
  transform: rotate(-45deg);
  top: 10px;
}

.header__btn span {
  position: absolute;
  left: 0;
  width: 24px;
  height: 2px;
  background-color: #fff;
  border-radius: 3px;
  transition: 0.5s ease;
  opacity: 1;
}

.section {
  position: relative;
  overflow: hidden;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section--pb0 {
  padding-bottom: 0;
}

.section--first {
  padding-top: 130px;
}

.stakingMofo {
  text-align: center;
  height: auto;
  position: relative;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

@media (max-width: 992px) {
  .stakingMofo {
    width: 100%;
  }
}

.stake_info_general {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.staking__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 2px solid rgba(255,0,0,0.12);
  padding-bottom: 30px;
}

.staking__info h2 {
  font-size: 20px;
  line-height: 24px;
  color: #25292e;
  font-weight: 500;
  margin-bottom: 10px;
}

.staking__info p {
  font-size: 16px;
  line-height: 24px;
  color: rgba(255,0,0,0.65);
  margin-bottom: 0;
  font-weight: 500;
  width: 100%;
}

.message-stakeing {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: #25292e;
  width: 100%;
}

.top-40 {
  margin-top: 40px !important;
}

button:disabled {
  opacity: .35;
}

.staking__group {
  margin-top: 30px;
  width: 100%;
}

.stake_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.elmoBlack {
  color: #25292e !important;
}

.staking__label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #25292e;
  margin-bottom: 15px;
  border: 2px solid rgba(255,0,0,0.25);
  border-radius: 10px;
  padding: 5px 12px;
  width: 165px;
  text-align: center;
}

.staking__label_second {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #25292e;
  margin-bottom: 15px;
  border: 2px solid rgba(255,0,0,0.25);
  border-radius: 10px;
  padding: 5px 12px;
  text-align: center;
}

.staking__label_second .amount {
  color: #25292e;
  font-size: 21px;
  font-weight: 500;
}

.staking__label_second .label {
  color: #25292e;
  font-size: 14px;
  font-weight: 500;
}

.staking__label_1 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #25292e;
  width: 100%;
  line-height: 100%;
  margin-bottom: 15px;
}

.staking__input {
  width: 100%;
  height: 48px;
  padding: 0 15px;
  border: 2px solid rgba(255,255,255,0.8);
  box-shadow: 0px 0px 15px #4377FE;
  background-color: transparent;
  border-radius: 10px;
  font-size: 15px;
  color: #FFFFFF;
  cursor: default;
  font-weight: 500;
}

.staking__input::placeholder {
  color: #FFFFFF;
}

.staking__input:hover, .staking__input:focus {
  border-color: rgba(255,255,255,0.8);
  outline: none;
}

.staking__btn {
  position: relative;
  width: 100%;
  height: 48px;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(255,0,0,0.5) !important;
  border-radius: 10px !important;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 15px !important;
  font-weight: 700 !important;
  letter-spacing: 1px;
  border: 2px solid rgba(255,0,0,0.5) !important;
  margin-top: 15px;
}

.loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #4377FE;
  font-size: 16px;
  text-align: center;
  line-height: 100px;
  background: rgba(3, 3, 17, .45);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.loader-wrap.not-connected {
  height: 275px !important;
  bottom: 0 !important;
  top: unset !important;
}

.spinner-border {
  width: 45px !important;
  height: 45px !important;
}

.header__nav {
  position: fixed;
  width: 280px;
  top: 80px;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,.2);
  padding: 30px 15px 0;
  transition: transform 0.5s ease;
  transform: translate3d(-280px, 0, 0);
  border-top: 1px solid rgba(242,12,38, .1);
}

.header__nav li {
  display: block;
  margin-bottom: 25px;
}

.header__nav a {
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  color: #FFFFFF;
  text-transform: uppercase;
}

.header__nav a:hover {
  color: #fff;
}

a {
  text-decoration: none !important;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
}

ul {
  margin: 0 !important;
  padding: 0;
  list-style: none;
}

.minting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  position: relative;
  padding: 30px;
  background: rgba(3, 3, 17, .45);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  text-align: center;
}

.myNft {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  box-sizing: border-box;
}

.myNft .nft {
  display: flex;
  align-items: center;
  padding: 16px;
  background: rgba(3, 3, 17, .45);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  max-width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.myNft .nft .nft-show {
  width: 250px;
  height: 250px;
}

.myNft .nft .nft-show img {
  width: 100%;
}

.myNft .card-at {
  height: 300px;
  perspective: 800px;
  overflow: visible;
  position: relative;
}

.myNft .card-at .box-ct {
  width: 250px;
  height: 250px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) rotateX(10deg);
  transform-style: preserve-3d;
  animation: swingY 12s linear infinite;
  overflow: visible;
}

.myNft .card-at .box-ct .box-side:first-child {
  width: 250px;
  height: 250px;
  transform: translateZ(30px);
}

.myNft .card-at .box-ct .box-side {
  border: 1px solid hsla(0,0%,100%,.75);
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
}

.myNft .card-at .light-scan-ct {
  overflow: hidden;
}

.myNft .card-at .card-decoration:before {
  display: block;
}

.myNft .card-at .card-decoration:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  border-radius: 0;
  transform: translate(-50%,-50%);
  filter: brightness(380%) opacity(.1);
  z-index: -1;
  background-image: url(/public/sparkles.gif);
  background-size: cover;
  overflow: hidden;
}

.myNft .card-at .light-scan {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 200%;
  height: 600px;
  background-image: linear-gradient(180deg,hsla(0,0%,100%,0) 44%,hsla(0,0%,100%,.1) 50%,hsla(0,0%,100%,0) 56%);
  transform-origin: center center;
  transform: translate(-100%,-50%) rotate(-60deg);
  animation: lightScanAnim 5s linear 1s infinite;
}

.myNft .card-at .box-ct .box-side:nth-child(2) {
  width: 250px;
  height: 250px;
  transform: translateZ(-30px) rotateY(180deg);
  background: linear-gradient(135deg,rgba(227,125,61,.1),hsla(0,0%,100%,.05) 50%,rgba(197,61,227,.1));
}

.myNft .card-at .box-ct .box-side:nth-child(3) {
  width: 60px;
  height: 250px;
  transform: rotateY(-90deg) translateZ(30px);
  background: linear-gradient(135deg,rgba(0,163,255,.1),hsla(0,0%,100%,.05) 50%,rgba(227,125,61,.1));
}

.myNft .card-at .box-ct .box-side:nth-child(4) {
  width: 60px;
  height: 250px;
  transform: rotateY(90deg) translateZ(220px);
  background: linear-gradient(-45deg,rgba(186,61,227,.1),hsla(0,0%,100%,.05) 50%,rgba(227,125,61,.1));
}

.myNft .card-at .box-ct .box-side:nth-child(5) {
  width: 250px;
  height: 60px;
  transform: translateY(-30px) rotateX(90deg);
  background: linear-gradient(135deg,rgba(0,163,255,.1),hsla(0,0%,100%,.05) 50%,rgba(227,125,61,.1));
}

.myNft .card-at .box-ct .box-side:nth-child(6) {
  width: 250px;
  height: 60px;
  transform: translateY(220px) rotateX(-90deg);
  background: linear-gradient(45deg,rgba(227,125,61,.1),hsla(0,0%,100%,.05) 50%,rgba(186,61,227,.1));
}

.myNft .card-at .box-ct .box-side {
  border: 1px solid hsla(0,0%,100%,.75);
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
}

.myNft .card-at .card-inr {
  width: 92%;
  height: 92%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-style: preserve-3d;
  transform: translate(-50%,-50%);
}

.myNft .card-at .card-inr .nft-card-ct {
  width: 100%;
  padding: 4px;
  position: relative;
  overflow: hidden;
}

.myNft .card-at .card-inr img {
  width: 100%;
  position: relative;
  z-index: 2;
}

.myNft li, .myNft ul {
  list-style: none;
  padding: 0 !important;
}

.nft-info-list-item {
  font-size: 14px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  font-weight: 300;
}

.nft-info-list-item .nft-token-value {
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  font-weight: 400;
}

.nft-info {
  max-width: 350px;
  width: 100% !important;
  margin-top: 55px;
  text-align: center;
}

.general-info {
  display: flex;
  flex-direction: column;
}

.minting__btn {
  background: rgba(3, 3, 17, .65);
  width: 100%;
  margin-top: 15px;
  border: 1px solid rgba(255,255,255,0.2);
  box-shadow: 0px 0px 3px #4377FE;
  background-color: transparent;
}

@keyframes swingY{
  0% {
    transform:translate(-50%,-50%) rotateY(0)
  }
  25% {
    transform:translate(-50%,-50%) rotateY(-10deg)
  }
  50% {
    transform:translate(-50%,-50%) rotateY(0)
  } 
  75% {
    transform:translate(-50%,-50%) rotateY(10deg)
  } to {
    transform:translate(-50%,-50%) rotateY(0)
  }
}

@keyframes lightScanAnim {
  0% {
    transform:translate(-100%,-50%) rotate(-60deg)
  } to {
    transform:translate(100%,-50%) rotate(-60deg)
  }
}

@media (min-width: 1024px) {
  .myNft .nft .nft-show {
    flex-direction: row;
    height: auto;
  }
  .nft-info {
    width: auto;
  }
}

@media (max-width: 430px) {
  .loader-wrap.not-connected {
    height: 225px !important;
  }
}

@media (min-width: 576px) {
  .header__nav {
    padding-left: calc((100vw - 516px) / 2);
    width: 320px;
    transform: translate3d(-320px, 0, 0);
  }
}

.header__nav--active {
  transform: translate3d(0, 0, 0);
  height: 100vh;
  background: rgb(16,12,35);
}

@media (max-width: 768px) {
  .myNft {
    grid-template-columns: repeat(1, 1fr);
  }
  .minting {
    padding: 15px;
  }
}

@media (min-width: 768px) {
  .section {
      padding: 70px 0;
  }
  .section--pb0 {
    padding-bottom: 0;
  }
  .section--first {
    padding-top: 180px;
  }
  .staking {
    padding: 40px;
  }
  .staking__info {
    margin-bottom: 10px;
  }
  .staking__info h2 {
    font-size: 24px;
    line-height: 24px;
  }
  .staking__info p {
    font-size: 18px;
    line-height: 24px;
  }
  .staking__group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
  }
  .stake_info {
    flex-direction: row;
  }
  .header__nav {
    padding-left: calc((100vw - 696px) / 2);
  }
}

@media (min-width: 992px) {
  .header__nav {
    padding-left: calc((100vw - 936px) / 2);
  }
}

@media (max-width: 992px) {
  .btm {
    margin-bottom: 25px;
  }
  .header__logo img {
    height: 65px;
  }
}

@media (min-width: 1400px) {
  .staking__label {
    width: 205px;
  }
  .nft-info {
    margin-top: 0;
  }
  .nft-info-list-item {
    font-size: 15px;
    margin-bottom: 6px;
  }
}

@media (max-width: 1200px) {
  .staking__label {
    width: 100%;
  }
  .header__nav {
    height: 100vh;
    background: rgb(16,12,35);
  }
}

@media (min-width: 1200px) {
  .header__logo {
    margin: 0;
  }
  .header__btn {
    display: none;
  }
  .header__nav {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    width: auto;
    transform: translate3d(0, 0, 0);
    padding: 0;
    margin-right: auto;
    margin-left: 60px !important;
    border-top: none;
    z-index: 2;
  }
  .header__nav li {
    display: block;
    margin-right: 44px;
    margin-bottom: 0;
  }
  .header__nav a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #FFFFFF;
    font-weight: 400;
    text-transform: none;
  }
}

.burn-btn:not(:last-child) {
  margin-right: 10px;
}

.burn-btn {
  font-size: 14px !important;
  padding: 3px 10px !important;
}

.burn-btn.active {
  color: #FFF !important;
  background-color: #2B0C50 !important;
  border-color: #2B0C50 !important;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  text-decoration: none;
  width: 100%;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: rgba(255,0,0,0.5);
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.custom-card {
  background-color: #10131d !important;
  box-shadow: 0 0 10px 2px rgba(242,12,38, .3);
}

.card-title {
  font-size: 1.45rem;
  text-transform: uppercase;
  color: rgb(242,12,38);
  letter-spacing: 1px;
}

.card-text {
  font-size: 1.35rem;
  font-weight: 500;
  margin: 0.5rem 0;
  color: #d5d5d5;
}
